import { LinksFunction, LoaderFunctionArgs } from '@remix-run/cloudflare';
import { Links, Meta, MetaFunction, Outlet, Scripts, ScrollRestoration, json } from '@remix-run/react';
import { AnalyticsTracking } from '~/my-components/AnalyticsTracking';
import stylesheet from '~/tailwind.css?url';
import { RootLoaderReturn } from '~/types';
import { getUserId, getUserSession, logout } from '~/utils/auth.server';
import { ClientHintCheck } from '~/utils/client-hints';
import { getPrisma } from '~/utils/db.server';
import { useNonce } from '~/utils/nonce-provider';
import { getRequestInfo } from '~/utils/request-info';
import { isNullOrUndefined } from '~/utils/typecheck';

const fakeUser: RootLoaderReturn['user'] = {
  id: '1',
  name: 'Will Smith',
  type: 'fake' as const,
  email: 'willsmith@gmail.com',
  key: 'abc',
  hourlyRate: 80,
};

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const userId = await getUserId(request, context);
  const session = await getUserSession(request, context);
  let user = { ...fakeUser, hourlyRate: session.data.hourlyRate ?? fakeUser.hourlyRate };
  if (userId) {
    const prisma = getPrisma({ context });
    const prismaUser = await prisma.user.findUnique({
      where: { id: userId },
      select: {
        id: true,
        email: true,
        name: true,
        key: true,
        hourlyRate: true,
      },
    });
    if (isNullOrUndefined(prismaUser)) {
      return logout(request, context);
    }
    user = { ...prismaUser, type: 'real' as const, hourlyRate: prismaUser.hourlyRate?.toNumber() ?? null };
  }
  const returnData: RootLoaderReturn = {
    user,
    requestInfo: getRequestInfo(request),
  };

  return json(returnData);
};

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: stylesheet }];

export const meta: MetaFunction = () => {
  return [
    { title: 'Deliberate Time' },
    {
      name: 'description',
      content: '',
    },
  ];
};

export function Layout({ children }: { children: React.ReactNode }) {
  const nonce = useNonce();
  return (
    <html lang="en">
      <head>
        <ClientHintCheck nonce={nonce} />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <AnalyticsTracking />
      </head>
      <body className="bg-background text-foreground ">
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export { GeneralErrorBoundary as ErrorBoundary } from '~/my-components/GeneralErrorBoundary';

export default function App() {
  return <Outlet />;
}
